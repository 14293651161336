import React, { useEffect } from 'react';

import classes from './InfoSystemSelectorExtManagement.module.css';
import RightSideBarFormSelector from '../RightSideBarFormSelector/RightSideBarFormSelector';
import { selectorActiveInfoSystem, selectorInfoSystems } from '../../store/createNewConnectionSlice';
import { ConnectionSettingsItemType, ItemComboboxType } from '../../types/types';
import { useAppSelector } from '../../hooks/hooks';

interface IInfoSystemSelectorProps {
  connectionSettings: ConnectionSettingsItemType[] | undefined;
  setConnectionSettings?: (connectionSettings: ConnectionSettingsItemType[] | undefined) => void;
}

const InfoSystemSelectorExtManagement: React.FC<IInfoSystemSelectorProps> = ({
  connectionSettings,
  setConnectionSettings,
}) => {
  const infoSystems = useAppSelector(selectorInfoSystems);
  const activeInfoSystem = useAppSelector(selectorActiveInfoSystem);
  const infoSystemParam = connectionSettings?.find((setting) => setting.key === 'info_system');

  console.log('infoSystemParam', infoSystemParam);

  useEffect(() => {
    if (
      !infoSystemParam?.value &&
      activeInfoSystem?._uuid &&
      setConnectionSettings &&
      connectionSettings &&
      infoSystemParam
    ) {
      setConnectionSettings(
        connectionSettings.map((setting) =>
          setting.key === 'info_system' ? { ...setting, value: activeInfoSystem._uuid } : setting
        )
      );
    }
  }, [activeInfoSystem, connectionSettings, infoSystemParam?.value, setConnectionSettings, infoSystemParam]);

  const infoSystemsItems: ItemComboboxType[] | undefined = infoSystems?.map((system) => ({
    label: system.name ? system.name : '',
    id: system._uuid,
    uuid: system._uuid,
  }));

  const activeInfoSystemItemIndex = infoSystemsItems?.findIndex((item) => item.uuid === infoSystemParam?.value);
  const activeInfoSystemValue =
    activeInfoSystemItemIndex !== undefined && infoSystemsItems
      ? infoSystemsItems[activeInfoSystemItemIndex]
      : undefined;

  const onChangeInfoSystems = (value: ItemComboboxType | null) => {
    const uuid = value?.uuid;
    if (uuid && setConnectionSettings && connectionSettings) {
      setConnectionSettings(
        connectionSettings.map((setting) => (setting.key === 'info_system' ? { ...setting, value: uuid } : setting))
      );
    }
  };

  if (!infoSystemParam) {
    return null;
  }

  return (
    <div className={classes.container}>
      <RightSideBarFormSelector
        value={activeInfoSystemValue}
        items={infoSystemsItems}
        onChange={onChangeInfoSystems}
        placeholder={'Выбрать'}
        label={'Интегрируемая система'}
        className={classes.selector}
      />
    </div>
  );
};

export default InfoSystemSelectorExtManagement;
