import React from 'react';

import classes from './ProcedureTriggerFuntionCommonInfoTab.module.css';
import CollapseBlock from '../../../../../../Components/CollapseBlock/CollapseBlock';
import CardsContentBlock from '../../../../../../Components/CardsContentBlock/CardsContentBlock';
import SystemCard from '../../../../../../Components/SystemCard/SystemCard';
import { BookmarkRdbCdbType, BookmarkType } from '../../../../../../types/types';
import { formatDateDDMMYYYY } from '../../../../../../utils/Formatters/formatDateDDMMYYYY';
import CardInputLongDescription from '../../../../../../Components/CardInputLongDescription/CardInputLongDescription';
import { useBookmarksView } from '../../../../../../hooks/useBookmarksView';

interface IRdbCommonInfoTabProps {
  bookmark: BookmarkType;
}

const ProcedureTriggerFunctionCommonInfoTab: React.FC<IRdbCommonInfoTabProps> = ({ bookmark }) => {
  const data = bookmark.data as BookmarkRdbCdbType | undefined;
  const { isOpenBookmarkCollapseTextField, textFieldValue, onIsOpenChangeCollapseTextField, onChangeTextFieldValue } =
    useBookmarksView({ bookmarkId: bookmark.hash, bookmarkDescription: bookmark.data?.description });

  console.log('data', data);

  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>
        <CollapseBlock title={'Основное'} isOpen={true}>
          <CardInputLongDescription
            bookmark={bookmark}
            textFieldValue={textFieldValue}
            isModeEdit={isOpenBookmarkCollapseTextField}
            onIsModeEditChange={onIsOpenChangeCollapseTextField}
            onChangeTextFieldValue={onChangeTextFieldValue}
          />
          <CardsContentBlock
            title={'Описание из системы-источник'}
            description={data?.comment ? data.comment : '-'}
            className={classes.description}
          />
        </CollapseBlock>
        <CollapseBlock title={'Дополнительно'} isOpen={true}>
          <CardsContentBlock title={'Тип объекта'} description={data?.kind ? data.kind : '-'} />
          {bookmark.type === 'cdb' && (
            <CardsContentBlock title={'Движок'} description={data?.engine ? data.engine : '-'} />
          )}
          <CardsContentBlock
            title={'Создано в системе - источнике'}
            description={
              data?.created_in_source_system_at ? formatDateDDMMYYYY(new Date(data.created_in_source_system_at)) : '-'
            }
          />
          <CardsContentBlock
            title={'Изменено в системе - источнике'}
            description={
              data?.altered_in_source_system_at ? formatDateDDMMYYYY(new Date(data.altered_in_source_system_at)) : '-'
            }
          />
        </CollapseBlock>
      </div>
      <div className={classes.rightColumn}>
        <SystemCard
          systemUuid={data?.system._uuid}
          systemName={data?.system.name}
          systemSourceIco={data?.source_icon}
          systemSourceType={data?.source_type}
          connection_name={data?.connection_name}
          last_scanned_at={data?.last_scanned_at}
          created_on_portal_ts={data?.created_on_portal_ts}
          updated_on_portal_ts={data?.updated_on_portal_ts}
        />
      </div>
    </div>
  );
};

export default ProcedureTriggerFunctionCommonInfoTab;
