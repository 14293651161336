import React, { useEffect } from 'react';

import classes from './Card1CAttributeContent.module.css';
import Info1CAttributeRow from './Info1CAttributeRow/Info1CAttributeRow';
import { BookmarkType } from '../../../../types/types';
import CardTabsRow, { CardTabType } from '../../../../Components/CardTabsRow/CardTabsRow';
import Content1CAttributeByTabs from './Content1CAttributeByTabs/Content1CAttributeByTabs';
import { useActiveTab } from '../../../../hooks/useActiveTab';
import { useLoadBookmarkData } from '../../../../hooks/useLoadBookmarkData';

interface ICardSchemaContentProps {
  bookmark: BookmarkType;
}

export const TABS_1C_ATTRIBUTE: CardTabType[] = [{ id: 0, label: 'Общая информация' }];

const Card1CAttributeContent: React.FC<ICardSchemaContentProps> = ({ bookmark }) => {
  const loadBookmarkData = useLoadBookmarkData();
  const [activeTab, setActiveTab] = useActiveTab({ bookmark, tabs: TABS_1C_ATTRIBUTE });

  useEffect(() => {
    loadBookmarkData(bookmark);
  }, [bookmark, loadBookmarkData]);

  return (
    <div className={classes.container}>
      <Info1CAttributeRow bookmark={bookmark} />
      <CardTabsRow tabs={TABS_1C_ATTRIBUTE} activeTab={activeTab} setActiveTab={setActiveTab} />
      <Content1CAttributeByTabs activeTab={activeTab} bookmark={bookmark} />
    </div>
  );
};

export default Card1CAttributeContent;
