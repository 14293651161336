import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ConnectionAssetType,
  ConnectionDetailType,
  ConnectionSettingsItemType,
  InfoSystemType,
  ItemComboboxType,
} from '../types/types';
import { RootState } from './store';
import { serverApi } from '../api/serverApi';
import { getRejectedValue } from './rejectedValueHelper';

export type EditConnectionSliceType = {
  editConnection: ConnectionDetailType | undefined;
  fullEditConnectionAssetsList: ConnectionAssetType[] | null | undefined;

  infoSystems: InfoSystemType[];
  isLoadingInfoSystem: boolean;
  activeInfoSystem?: InfoSystemType & { id: string | number };

  isEditConnectionChecked: boolean;
  isRequiredEditConnectionName: boolean;
  isRequiredEditConnectionUser: boolean;
  isRequiredEditConnectionPassword: boolean;
  isRequiredEditConnectionSelectedAssets: boolean;

  saveEditConnectionMessage: string | undefined;
};

const initialState: EditConnectionSliceType = {
  editConnection: undefined,
  fullEditConnectionAssetsList: undefined,

  infoSystems: [],
  isLoadingInfoSystem: false,
  activeInfoSystem: undefined,

  isEditConnectionChecked: false,
  isRequiredEditConnectionName: false,
  isRequiredEditConnectionUser: false,
  isRequiredEditConnectionPassword: false,
  isRequiredEditConnectionSelectedAssets: false,

  saveEditConnectionMessage: undefined,
};

export const getInfoSystemsDictionaryEditConnectionThunk = createAsyncThunk<
  InfoSystemType[],
  string | undefined,
  { rejectValue: string }
>('editConnectionSlice/getInfoSystemsDictionaryThunk', async (params, { rejectWithValue }) => {
  try {
    return await serverApi.getInfoSystemsDictionary(params);
  } catch (e) {
    return rejectWithValue(getRejectedValue('Подключения - Ошибка получения списка Информационных Систем', e));
  }
});

export const editConnectionSlice = createSlice({
  name: 'editConnectionSlice',
  initialState,
  reducers: {
    clearEditConnection: () => initialState,
    setEditConnection: (state, action) => {
      state.editConnection = action.payload;
    },
    setFullAssetsList: (state, action) => {
      state.fullEditConnectionAssetsList = action.payload;
    },
    setIsEditConnectionChecked: (state, action) => {
      state.isEditConnectionChecked = action.payload;
    },
    setEditConnectionName: (state, action) => {
      if (state.editConnection) {
        state.editConnection.name = action.payload;
        if (state.isRequiredEditConnectionName) {
          state.isRequiredEditConnectionName = false;
        }
      }
    },
    setEditConnectionDescription: (state, action) => {
      if (state.editConnection) {
        state.editConnection.description = action.payload;
      }
    },
    setEditConnectionUser: (state, action) => {
      if (state.editConnection) {
        state.editConnection.user = action.payload;
        if (state.isEditConnectionChecked) {
          state.isEditConnectionChecked = false;
        }
        if (state.isRequiredEditConnectionUser) {
          state.isRequiredEditConnectionUser = false;
        }
      }
    },
    setEditConnectionPassword: (state, action) => {
      if (state.editConnection) {
        state.editConnection.password = action.payload;
        if (state.isEditConnectionChecked) {
          state.isEditConnectionChecked = false;
        }
        if (state.isRequiredEditConnectionPassword) {
          state.isRequiredEditConnectionPassword = false;
        }
      }
    },
    setGeneralValidationFlags: (state, action) => {
      if (action.payload?.includes('requiredName')) {
        state.isRequiredEditConnectionName = true;
      }
    },
    setSettingsValidationFlags: (state, action) => {
      if (action.payload?.includes('requiredConnectionUser')) {
        state.isRequiredEditConnectionUser = true;
      }
      if (action.payload?.includes('requiredConnectionPassword')) {
        state.isRequiredEditConnectionPassword = true;
      }
      if (action.payload?.includes('requiredConnectionAssets')) {
        state.isRequiredEditConnectionSelectedAssets = true;
      }
    },
    setEditConnectionAssets: (state, action) => {
      const newAssets: ConnectionAssetType[] = [];
      const items = action.payload as ItemComboboxType[] | null;
      if (items && state.fullEditConnectionAssetsList) {
        items.forEach((item) => {
          const selectedAsset = state.fullEditConnectionAssetsList?.find((asset) => asset.name === item.label);
          if (selectedAsset) {
            selectedAsset.settings
              ? newAssets.push({ ...selectedAsset })
              : newAssets.push({
                  ...selectedAsset,
                  settings: state.editConnection?.settings ? state.editConnection?.settings : [],
                });
          }
        });
      }
      if (!!newAssets.length) {
        state.editConnection && (state.editConnection.assets = newAssets);
      } else {
        state.editConnection && (state.editConnection.assets = undefined);
      }
      state.isRequiredEditConnectionSelectedAssets === true && (state.isRequiredEditConnectionSelectedAssets = false);
    },
    setEditConnectionFullAssetsList: (state, action) => {
      state.fullEditConnectionAssetsList = action.payload as ConnectionAssetType[] | null;

      let actualEditConnectionAssets: ConnectionAssetType[] = [];
      state.editConnection?.assets?.forEach((existedAsset) => {
        if (state?.fullEditConnectionAssetsList?.find((checkAsset) => existedAsset.name === checkAsset.name)) {
          actualEditConnectionAssets.push(existedAsset);
        }
      });
      if (!!actualEditConnectionAssets.length) {
        state.editConnection?.assets && (state.editConnection.assets = actualEditConnectionAssets);
      } else {
        state.editConnection?.assets && (state.editConnection.assets = undefined);
      }
    },
    setEditConnectionSettings: (state, action) => {
      if (state.editConnection) {
        state.editConnection.settings = action.payload;
        if (
          !action.payload.find((setting: ConnectionSettingsItemType) => setting.key === 'limits_for_preview')?.value
        ) {
          state.editConnection.previewSchedule = undefined;
        }
      }
    },
    setEditConnectionSettingsToAllAssets: (state) => {
      if (!!state.editConnection?.assets?.length && state.editConnection.settings) {
        state.editConnection.assets.forEach((asset) => {
          if (state.editConnection?.settings) {
            asset.settings = state.editConnection?.settings;
          }
        });
      }
    },
    setEditConnectionScanSchedule: (state, action) => {
      if (state.editConnection) {
        state.editConnection.scanSchedule = action.payload;
      }
    },
    setEditConnectionPreviewSchedule: (state, action) => {
      if (state.editConnection) {
        state.editConnection.previewSchedule = action.payload;
      }
    },
    setEditConnectionAssetSettings: (state, action) => {
      const changedAsset = state.editConnection?.assets?.find(
        (asset) => asset.name === action.payload.connectionAsset.name
      );
      if (changedAsset) {
        changedAsset.settings = action.payload.assetSettings;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInfoSystemsDictionaryEditConnectionThunk.fulfilled, (state, action) => {
        state.isLoadingInfoSystem = false;
        state.infoSystems = action.payload;
      })
      .addCase(getInfoSystemsDictionaryEditConnectionThunk.pending, (state) => {
        state.infoSystems = [];
        state.isLoadingInfoSystem = true;
      })
      .addCase(getInfoSystemsDictionaryEditConnectionThunk.rejected, (state, action) => {
        state.isLoadingInfoSystem = false;
        console.log(action.payload ? action.payload.toString() : 'Неизвестная ошибка - createNewConnectionsSlice');
      });
  },
});

export const {
  clearEditConnection,
  setEditConnection,
  setFullAssetsList,
  setIsEditConnectionChecked,
  setEditConnectionName,
  setEditConnectionDescription,
  setEditConnectionUser,
  setEditConnectionPassword,
  setGeneralValidationFlags,
  setSettingsValidationFlags,
  setEditConnectionAssets,
  setEditConnectionFullAssetsList,
  setEditConnectionSettings,
  setEditConnectionSettingsToAllAssets,
  setEditConnectionScanSchedule,
  setEditConnectionPreviewSchedule,
  setEditConnectionAssetSettings,
} = editConnectionSlice.actions;

export const selectorEditConnection = (state: RootState) => state.editConnection.editConnection;
export const selectorEditConnectionInfoSystems = (state: RootState) => state.editConnection.infoSystems;
export const selectorEditConnectionIsLoadingInfoSystem = (state: RootState) => state.editConnection.isLoadingInfoSystem;
export const selectorFullEditConnectionAssetsList = (state: RootState) =>
  state.editConnection.fullEditConnectionAssetsList;
export const selectorIsEditConnectionChecked = (state: RootState) => state.editConnection.isEditConnectionChecked;
export const selectorIsRequiredEditConnectionSelectedAssets = (state: RootState) =>
  state.editConnection.isRequiredEditConnectionSelectedAssets;
export const selectorIsRequiredEditConnectionUser = (state: RootState) =>
  state.editConnection.isRequiredEditConnectionUser;
export const selectorIsRequiredEditConnectionPassword = (state: RootState) =>
  state.editConnection.isRequiredEditConnectionPassword;
export const selectorIsRequiredEditConnectionName = (state: RootState) =>
  state.editConnection.isRequiredEditConnectionName;

export default editConnectionSlice.reducer;
