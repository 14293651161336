import { useEffect, useState } from 'react';

type bookmarkViewType = {
  id: string | number;
  isOpenCollapseBlockMain: boolean;
  isOpenCollapseBlockAdditional: boolean;
  isOpenTextField: boolean;
  textFieldValue: string;
};

export const useBookmarksView = ({
  bookmarkId,
  bookmarkDescription,
}: {
  bookmarkId: string | number;
  bookmarkDescription: string | null | undefined;
}) => {
  const [bookmarksView, setBookmarkView] = useState<bookmarkViewType[]>([]);

  useEffect(() => {
    const existedBookmark = bookmarksView.find((bookmarkView) => bookmarkView.id === bookmarkId);
    if (!existedBookmark) {
      const newBookmarkView = {
        id: bookmarkId,
        isOpenCollapseBlockMain: true,
        isOpenCollapseBlockAdditional: true,
        isOpenTextField: false,
        textFieldValue: bookmarkDescription ? bookmarkDescription : '',
      };
      setBookmarkView([...bookmarksView, newBookmarkView]);
    }
  }, [bookmarkId, bookmarksView, bookmarkDescription]);

  const isOpenBookmarkCollapseBlockMain = bookmarksView.find(
    (bookmarkView) => bookmarkView.id === bookmarkId
  )?.isOpenCollapseBlockMain;

  const isOpenBookmarkCollapseBlockAdditional = bookmarksView.find(
    (bookmarkView) => bookmarkView.id === bookmarkId
  )?.isOpenCollapseBlockAdditional;

  const isOpenBookmarkCollapseTextField = bookmarksView.find(
    (bookmarkView) => bookmarkView.id === bookmarkId
  )?.isOpenTextField;

  const textFieldValue = bookmarksView.find((bookmarkView) => bookmarkView.id === bookmarkId)?.textFieldValue;

  const onIsOpenChangeCollapseBlockMain = () => {
    setBookmarkView(
      bookmarksView.map((bookmarkView) =>
        bookmarkView.id === bookmarkId
          ? { ...bookmarkView, isOpenCollapseBlockMain: !bookmarkView.isOpenCollapseBlockMain }
          : bookmarkView
      )
    );
  };

  const onIsOpenChangeCollapseBlockAdditional = () => {
    setBookmarkView(
      bookmarksView.map((bookmarkView) =>
        bookmarkView.id === bookmarkId
          ? { ...bookmarkView, isOpenCollapseBlockAdditional: !bookmarkView.isOpenCollapseBlockAdditional }
          : bookmarkView
      )
    );
  };

  const onIsOpenChangeCollapseTextField = (isOpen: boolean) => {
    setBookmarkView(
      bookmarksView.map((bookmarkView) =>
        bookmarkView.id === bookmarkId ? { ...bookmarkView, isOpenTextField: isOpen } : bookmarkView
      )
    );
  };

  const onChangeTextFieldValue = (value: string) => {
    setBookmarkView(
      bookmarksView.map((bookmarkView) =>
        bookmarkView.id === bookmarkId ? { ...bookmarkView, textFieldValue: value } : bookmarkView
      )
    );
  };

  return {
    isOpenBookmarkCollapseBlockMain,
    isOpenBookmarkCollapseBlockAdditional,
    isOpenBookmarkCollapseTextField,
    textFieldValue,
    onIsOpenChangeCollapseBlockMain,
    onIsOpenChangeCollapseBlockAdditional,
    onIsOpenChangeCollapseTextField,
    onChangeTextFieldValue,
  };
};
