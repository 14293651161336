import React, { useEffect } from 'react';

import classes from './Card1CObjectWithTableContent.module.css';
import Info1CObjectWithTableRow from './Info1CObjectWithTableRow/Info1CObjectWithTableRow';
import { BookmarkType } from '../../../../types/types';
import CardTabsRow, { CardTabType } from '../../../../Components/CardTabsRow/CardTabsRow';
import Content1CObjectWithTableByTabs from './Content1CObjectWithTableByTabs/Content1CObjectWithTableByTabs';
import { useActiveTab } from '../../../../hooks/useActiveTab';
import { useLoadBookmarkData } from '../../../../hooks/useLoadBookmarkData';

interface ICardSchemaContentProps {
  bookmark: BookmarkType;
}

export const TABS_1C_OBJECT_WITH_TABLE_PART: CardTabType[] = [
  { id: 0, label: 'Общая информация' },
  { id: 1, label: 'Атрибуты' },
  { id: 2, label: 'Предпросмотр' },
];

const Card1CObjectWithTableContent: React.FC<ICardSchemaContentProps> = ({ bookmark }) => {
  const loadBookmarkData = useLoadBookmarkData();
  const [activeTab, setActiveTab] = useActiveTab({ bookmark, tabs: TABS_1C_OBJECT_WITH_TABLE_PART });

  useEffect(() => {
    loadBookmarkData(bookmark);
  }, [bookmark, loadBookmarkData]);

  return (
    <div className={classes.container}>
      <Info1CObjectWithTableRow bookmark={bookmark} />
      <CardTabsRow tabs={TABS_1C_OBJECT_WITH_TABLE_PART} activeTab={activeTab} setActiveTab={setActiveTab} />
      <Content1CObjectWithTableByTabs activeTab={activeTab} bookmark={bookmark} />
    </div>
  );
};

export default Card1CObjectWithTableContent;
