import React from 'react';

import classes from './AttributeCommonInfoTab.module.css';
import CollapseBlock from '../../../../../../Components/CollapseBlock/CollapseBlock';
import CardsContentBlock from '../../../../../../Components/CardsContentBlock/CardsContentBlock';
import SystemCard from '../../../../../../Components/SystemCard/SystemCard';
import { BookmarkAttributeType, BookmarkType } from '../../../../../../types/types';
import KeyIcoAndValue from '../../../../../../Components/KeyIcoAndValue/KeyIcoAndValue';
import CardInputLongDescription from '../../../../../../Components/CardInputLongDescription/CardInputLongDescription';
import { useBookmarksView } from '../../../../../../hooks/useBookmarksView';

interface IRdbCommonInfoTabProps {
  bookmark: BookmarkType;
}

const AttributeCommonInfoTab: React.FC<IRdbCommonInfoTabProps> = ({ bookmark }) => {
  const data = bookmark.data as BookmarkAttributeType | undefined;

  const { isOpenBookmarkCollapseTextField, textFieldValue, onIsOpenChangeCollapseTextField, onChangeTextFieldValue } =
    useBookmarksView({ bookmarkId: bookmark.hash, bookmarkDescription: bookmark.data?.description });

  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>
        <CollapseBlock title={'Основное'} isOpen={true}>
          <CardInputLongDescription
            bookmark={bookmark}
            textFieldValue={textFieldValue}
            isModeEdit={isOpenBookmarkCollapseTextField}
            onIsModeEditChange={onIsOpenChangeCollapseTextField}
            onChangeTextFieldValue={onChangeTextFieldValue}
          />
          <CardsContentBlock
            title={'Описание из системы-источник'}
            description={data?.comment ? data.comment : '-'}
            className={classes.description}
          />
        </CollapseBlock>
        <CollapseBlock title={'Дополнительно'} isOpen={true}>
          <CardsContentBlock
            title={'Унифицированный тип данных'}
            description={data?.unified_data_type ? data.unified_data_type : '-'}
          />
          <CardsContentBlock title={'Тип данных'} description={data?.type ? data.type : '-'} />
          <CardsContentBlock title={'Ключ'}>
            <KeyIcoAndValue is_in_primary_key={data?.is_in_primary_key} is_in_foreign_key={data?.is_in_foreign_key} />
          </CardsContentBlock>
          <CardsContentBlock title={'Допускает пустое значение'} description={data?.not_null ? data.not_null : '-'} />
          <CardsContentBlock title={'Порядковый номер'} description={data?.num ? data.num : '-'} />
          <CardsContentBlock
            title={'Значение по умолчанию'}
            description={data?.default_value ? data.default_value : '-'}
          />
        </CollapseBlock>
      </div>
      <div className={classes.rightColumn}>
        <SystemCard
          systemUuid={data?.system._uuid}
          systemName={data?.system.name}
          systemSourceIco={data?.source_icon}
          systemSourceType={data?.source_type}
          connection_name={data?.connection_name}
          last_scanned_at={data?.last_scanned_at}
          created_on_portal_ts={data?.created_on_portal_ts}
          updated_on_portal_ts={data?.updated_on_portal_ts}
        />
      </div>
    </div>
  );
};

export default AttributeCommonInfoTab;
