import { createAsyncThunk, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { RootState } from './store';
import {
  Bookmark1CObject,
  BookmarkDescriptionType,
  BookmarkProcedureTriggerFunctionType,
  BookmarkTableViewTableFunctionType,
  BookmarkType,
  CatalogSectionType,
  HierarchyItemType,
  ResponseBookmarkType,
  UpdateDescriptionParamsType,
} from '../types/types';
import {
  CardDataPreviewServerResponseType,
  CardScriptServerResponseType,
  CatalogFilterServerDictionaryResponseType,
  GetCatalogItemsServerResponseType,
  MetaFilterServerType,
  MetaFiltersServerResponseType,
  ObjectsTypesServerResponseType,
  TableViewTableFunctionAttributeServerResponseType,
} from '../api/serverResponse';
import { serverApi } from '../api/serverApi';
import { getRejectedValue } from './rejectedValueHelper';
import { MultiFilterType } from '../Components/FilterWithScrollAndSearch/FilterWithScrollAndSearch';
import { DataFilterStrType, DataFilterType } from '../Components/FilterData/FilterData';
import { FiltersGroupType } from '../Components/FiltersGroup/FiltersGroup';
import { addChildrenToHierarchyItem } from '../utils/Hierarchy/addChildrenToHierarchyItem';
import { setIsSelectHierarchyItem } from '../utils/Hierarchy/setIsSelectHierarchyItem';
import { setIsSelectForHierarchyItemAndAllChildren } from '../utils/Hierarchy/setIsSelectForHierarchyItemAndAllChildren';
import { searchParentInHierarchy } from '../utils/Hierarchy/searchParentInHierarchy';
import { searchParentInHierarchyByChildUuid } from '../utils/Hierarchy/searchParentInHierarchyByChildUuid';
import { setNoSelectForAllHierarchyItems } from '../utils/Hierarchy/setNoSelectForAllHierarchyItems';
import { checkIsAllGrandChildrenSelected } from '../utils/Hierarchy/checkIsAllGrandChildrenSelected';
import { VIEW_SELECTOR_ITEMS } from '../Pages/CatalogPage/BookmarkContentSwitcher/CatalogContent/ControlRow/ViewSelector/ViewSelector';
import { TableColumnType } from '../shared-front/Table/Table';
import { combineQuery } from '../utils/combineQuery';
import { setIsOpenHierarchyItem } from '../utils/Hierarchy/setIsOpenHierarchyItem';
import { closeAllHierarchyItems } from '../utils/Hierarchy/closeAllHierarchyItems';

type CatalogSliceType = {
  activeBookmarkKey: number;
  bookmarks: BookmarkType[];
  activeViewSelectorName: string;
  activeSortingId: number;
  catalogItems?: GetCatalogItemsServerResponseType;
  activeSection: CatalogSectionType | undefined;
  filtersServerList: MetaFilterServerType[];
  multiFilters: MultiFilterType[];
  dataFilters: DataFilterStrType[];
  groupFilters: FiltersGroupType[];
  isFiltersDictionaryAsked: boolean;
  isHierarchyOpen: boolean;
  hierarchy: {
    [key: string]: HierarchyItemType[];
  };
  tableColumns: {
    [key: string]: TableColumnType[];
  };
  isSearch: boolean;
  isLoading: boolean;
  isLoadingHierarchy: boolean;
  isLoadingAttributesData: boolean;
  isLoadingPreviewData: boolean;
  isLoadingScriptData: boolean;
  isLoadingCatalogItems: boolean;
  isLoadingBookmarkData: boolean;
  isUpdatingDescription: boolean;
  isError: string | undefined;
};

const initialState: CatalogSliceType = {
  activeSortingId: 0,
  bookmarks: [{ key: 0, label: 'Каталог', type: 'catalog', slug: 'catalog', hash: 'catalog' }],
  activeViewSelectorName: VIEW_SELECTOR_ITEMS[0].name,
  activeBookmarkKey: 0,
  catalogItems: undefined,
  activeSection: undefined,
  filtersServerList: [],
  multiFilters: [],
  dataFilters: [],
  groupFilters: [],
  isFiltersDictionaryAsked: false,
  isHierarchyOpen: false,
  hierarchy: {},
  tableColumns: {},
  isSearch: false,
  isLoading: false,
  isLoadingHierarchy: false,
  isLoadingCatalogItems: false,
  isLoadingAttributesData: false,
  isLoadingPreviewData: false,
  isLoadingBookmarkData: false,
  isLoadingScriptData: false,
  isUpdatingDescription: false,
  isError: undefined,
};

export const getCatalogItemsThunk = createAsyncThunk<
  GetCatalogItemsServerResponseType,
  string,
  { rejectValue: string }
>('meta/catalog/getCatalogItemsThunk', async (query, { rejectWithValue }) => {
  try {
    return await serverApi.getCatalogItems(query);
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка получения данных для раздела Каталога', e));
  }
});

export const getFiltersServerListThunk = createAsyncThunk<
  MetaFiltersServerResponseType,
  undefined,
  { rejectValue: string }
>('meta/catalog/getFiltersServerListThunk', async (_, { rejectWithValue }) => {
  try {
    return await serverApi.getServerCatalogFilters();
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка получения списка фильтров Каталога', e));
  }
});

export const getCatalogFilterDictionaryThunk = createAsyncThunk<
  { filterName: string; dictionary: CatalogFilterServerDictionaryResponseType },
  string,
  { rejectValue: string }
>('meta/catalog/getCatalogFilterDictionaryThunk', async (filterName, { rejectWithValue }) => {
  try {
    const dictionary = await serverApi.getCatalogFilterDictionary(filterName);
    return { filterName, dictionary };
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка получения словаря фильтра для Каталога', e));
  }
});

type GetHierarchyFilterItemsThunkType = {
  hierarchySlug: string;
  query?: string;
  parentUuid?: string;
};

export const getHierarchyItemsThunk = createAsyncThunk<
  { hierarchyItems: HierarchyItemType[]; hierarchySlug: string; parentUuid?: string },
  GetHierarchyFilterItemsThunkType,
  { rejectValue: string }
>('meta/catalog/getHierarchyFilterItemThunk', async ({ hierarchySlug, query, parentUuid }, { rejectWithValue }) => {
  try {
    const queryThunk = query ? combineQuery([query, `type=${hierarchySlug}`]) : combineQuery([`type=${hierarchySlug}`]);
    const hierarchyItems = await serverApi.getServerCatalogHierarchyItems(queryThunk);
    return { hierarchyItems, hierarchySlug, parentUuid };
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка получения данных фильтра Иерархии для Каталога', e));
  }
});

export const getBookmarkDataThunk = createAsyncThunk<
  { bookmarkData: ResponseBookmarkType; bookmark: BookmarkType },
  BookmarkType,
  { rejectValue: string }
>('meta/catalog/getBookmarkDataThunk', async (bookmark, { rejectWithValue }) => {
  try {
    const bookmarkData = await serverApi.getBookmarkData(bookmark);
    return { bookmarkData, bookmark };
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка получения данных для карточки каталога', e));
  }
});

export const getBookmarkAttributesDataThunk = createAsyncThunk<
  { attributesData: TableViewTableFunctionAttributeServerResponseType; bookmark: BookmarkType },
  { bookmark: BookmarkType; requestQuery: string },
  { rejectValue: string }
>('meta/catalog/getBookmarkAttributesDataThunk', async ({ bookmark, requestQuery }, { rejectWithValue }) => {
  try {
    const attributesData = await serverApi.getBookmarkAttributesData({ bookmark, requestQuery });
    return { attributesData, bookmark };
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка получения данных атрибутов в карточке каталога', e));
  }
});

export const getBookmarkPreviewDataThunk = createAsyncThunk<
  { previewData: CardDataPreviewServerResponseType; bookmark: BookmarkType },
  { bookmark: BookmarkType; requestQuery: string },
  { rejectValue: string }
>('meta/catalog/getBookmarkPreviewDataThunk', async ({ bookmark, requestQuery }, { rejectWithValue }) => {
  try {
    const previewData = await serverApi.getBookmarkPreviewData({ bookmark, requestQuery });
    return { previewData, bookmark };
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка получения данных предпросмотра в карточке каталога', e));
  }
});

export const getBookmarkScriptDataThunk = createAsyncThunk<
  { scriptData: CardScriptServerResponseType; bookmark: BookmarkType },
  BookmarkType,
  { rejectValue: string }
>('meta/catalog/getBookmarkScriptDataThunk', async (bookmark, { rejectWithValue }) => {
  try {
    const scriptData = await serverApi.getScriptData(bookmark);
    return { scriptData, bookmark };
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка получения данных скрипта в карточке каталога', e));
  }
});

export const updateDescriptionThunk = createAsyncThunk<
  { bookmarkData: ResponseBookmarkType; bookmark: BookmarkType },
  { bookmark: BookmarkType; params: UpdateDescriptionParamsType },
  { rejectValue: string }
>('meta/catalog/updateDescriptionThunk', async ({ bookmark, params }, { rejectWithValue }) => {
  try {
    const bookmarkData = await serverApi.updateDescription({ bookmark, params });
    return { bookmarkData, bookmark };
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка обновления описания для карточки каталога', e));
  }
});

export const getObjectsTypesThunk = createAsyncThunk<
  { objectsTypes: ObjectsTypesServerResponseType; bookmark: BookmarkType },
  BookmarkType,
  { rejectValue: string }
>('meta/catalog/getObjectsTypesThunk', async (bookmark, { rejectWithValue }) => {
  try {
    const objectsTypes = await serverApi.getObjectTypes(bookmark);
    return { objectsTypes, bookmark };
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка получения данных для типов обьектов', e));
  }
});

export const getObjectsThunk = createAsyncThunk<
  { objectsData: GetCatalogItemsServerResponseType; bookmark: BookmarkType; objectType: CatalogSectionType },
  { bookmark: BookmarkType; queryParams: string; objectType: CatalogSectionType },
  { rejectValue: string }
>('meta/catalog/getObjectsThunk', async ({ bookmark, queryParams, objectType }, { rejectWithValue }) => {
  try {
    const objectsData = await serverApi.getCatalogItems(queryParams);
    return { objectsData, bookmark, objectType };
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка получения обьектов', e));
  }
});

export const catalogSlice = createSlice({
  name: 'catalogSlice',
  initialState,
  reducers: {
    clearCatalogError: (state) => {
      state.isError = undefined;
    },
    setCatalogError: (state, action) => {
      state.isError = action.payload;
    },
    setActiveSection: (state, action) => {
      state.activeSection = action.payload;
    },
    setActiveViewSelectorName: (state, action) => {
      state.activeViewSelectorName = action.payload;
    },
    setActiveSortingId: (state, action) => {
      state.activeSortingId = action.payload;
    },
    setMultiFiltersStore: (state, action) => {
      state.multiFilters = action.payload;
    },
    setDataFiltersStore: (state, action) => {
      state.dataFilters = action.payload;
    },
    setGroupFiltersStore: (state, action) => {
      state.groupFilters = action.payload;
    },
    setIsFiltersDictionaryAsked: (state, action) => {
      state.isFiltersDictionaryAsked = action.payload;
    },
    toggleIsOpenHierarchy: (state) => {
      state.isHierarchyOpen = !state.isHierarchyOpen;
    },
    closeIsOpenHierarchy: (state, action) => {
      if (action.payload) {
        if (state.activeSection && state.activeSection.slug && state.hierarchy[`${state.activeSection.slug}`]) {
          state.hierarchy[`${state.activeSection.slug}`] = action.payload;
        }
      }
      state.isHierarchyOpen = false;
    },
    clearHierarchyItems: (state) => {
      state.hierarchy = {};
    },
    setTableColumns: (state, action) => {
      if (state.activeSection?.slug) {
        const newTableColumns = { ...state.tableColumns };
        newTableColumns[`${state.activeSection.slug}`] = action.payload;
        state.tableColumns = newTableColumns;
      }
    },
    setActiveBookmarkKey: (state, action) => {
      state.activeBookmarkKey = action.payload;
    },
    createNewBookmark: (state, action) => {
      const key = Math.max(...state.bookmarks.map((bookmark) => bookmark.key)) + 1;
      state.bookmarks.push({
        key,
        label: action.payload.label,
        type: action.payload.type,
        slug: action.payload.slug,
        hash: action.payload.hash,
      });
      state.activeBookmarkKey = key;
    },
    deleteBookmark: (state, action) => {
      state.bookmarks = state.bookmarks.filter((bookmark) => bookmark.key !== action.payload);
    },
    setIsSelectHierarchyItemStore: (state, action) => {
      if (state.activeSection && state.activeSection.slug && state.hierarchy[`${state.activeSection.slug}`]) {
        state.hierarchy[`${state.activeSection.slug}`] = setIsSelectHierarchyItem({
          hierarchyItems: state.hierarchy[`${state.activeSection.slug}`],
          itemUuid: action.payload.uuid,
          isChecked: action.payload.isChecked,
        });
      }
    },
    setNoSelectAllHierarchyItemsStore: (state) => {
      if (state.activeSection && state.activeSection.slug && state.hierarchy[`${state.activeSection.slug}`]) {
        state.hierarchy[`${state.activeSection.slug}`] = setNoSelectForAllHierarchyItems({
          hierarchyItems: state.hierarchy[`${state.activeSection.slug}`],
        });
      }
    },
    closeAllHierarchyItemsStore: (state) => {
      if (state.activeSection && state.activeSection.slug && state.hierarchy[`${state.activeSection.slug}`]) {
        state.hierarchy[`${state.activeSection.slug}`] = closeAllHierarchyItems({
          hierarchyItems: state.hierarchy[`${state.activeSection.slug}`],
        });
      }
    },
    setIsOpenHierarchyItemStore: (state, action) => {
      if (state.activeSection && state.activeSection.slug && state.hierarchy[`${state.activeSection.slug}`]) {
        state.hierarchy[`${state.activeSection.slug}`] = setIsOpenHierarchyItem({
          hierarchyItems: state.hierarchy[`${state.activeSection.slug}`],
          itemUuid: action.payload.uuid,
          isOpen: action.payload.isOpen,
        });
      }
    },
    checkAndDeCheckParents: (state, action) => {
      if (state.activeSection && state.activeSection.slug && state.hierarchy[`${state.activeSection.slug}`]) {
        let parent = searchParentInHierarchyByChildUuid(
          state.hierarchy[`${state.activeSection.slug}`],
          action.payload.uuid
        );
        while (parent) {
          const isParentAllSelected = checkIsAllGrandChildrenSelected(parent);
          if (isParentAllSelected && !parent.isChecked) {
            state.hierarchy[`${state.activeSection.slug}`] = setIsSelectHierarchyItem({
              hierarchyItems: state.hierarchy[`${state.activeSection.slug}`],
              itemUuid: parent.uuid,
              isChecked: true,
            });
          }
          if (!isParentAllSelected && parent.isChecked) {
            state.hierarchy[`${state.activeSection.slug}`] = setIsSelectHierarchyItem({
              hierarchyItems: state.hierarchy[`${state.activeSection.slug}`],
              itemUuid: parent.uuid,
              isChecked: false,
            });
          }
          parent = searchParentInHierarchy(state.hierarchy[`${state.activeSection.slug}`], parent);
        }
      }
    },

    setIsSelectForHierarchyItemAndAllChildrenStore: (state, action) => {
      if (state.activeSection && state.activeSection.slug && state.hierarchy[`${state.activeSection.slug}`]) {
        state.hierarchy[`${state.activeSection.slug}`] = setIsSelectForHierarchyItemAndAllChildren({
          hierarchyItems: state.hierarchy[`${state.activeSection.slug}`],
          itemUuid: action.payload.uuid,
          isChecked: action.payload.isChecked,
        });
      }
    },

    setActiveObjectTypeTab: (state, action) => {
      const bookmark = state.bookmarks.find((bookmark) => bookmark.hash === action.payload.bookmarkHash);
      if (bookmark) {
        if (!bookmark.objects) {
          bookmark.objects = {};
        }
        bookmark.objects.activeObjectTypeTab = action.payload.activeObjectTypeTab;
      }
    },

    setObjectsSearchValue: (state, action) => {
      const bookmark = state.bookmarks.find((bookmark) => bookmark.hash === action.payload.bookmarkHash);
      if (bookmark) {
        if (!bookmark.objects) {
          bookmark.objects = {};
        }
        bookmark.objects.searchValue = action.payload.searchValue;
      }
    },

    setBookmarkActiveTab: (state, action) => {
      const bookmark = state.bookmarks.find((bookmark) => bookmark.hash === action.payload.bookmarkHash);
      if (bookmark) {
        bookmark.activeTab = action.payload.activeTab;
      }
    },

    setBookmarkTableColumns: (state, action) => {
      const bookmark = state.bookmarks.find((bookmark) => bookmark.hash === action.payload.bookmarkHash);
      if (bookmark) {
        bookmark.tableColumns = action.payload.tableColumns;
      }
    },

    setBookmarkOpenedRows: (state, action) => {
      const bookmark = state.bookmarks.find((bookmark) => bookmark.hash === action.payload.bookmarkHash);
      if (bookmark) {
        const data = bookmark.data as Bookmark1CObject;
        data.openedRows = action.payload.openedRows;
      }
    },

    setBookmarkInnerRows: (state, action) => {
      const bookmark = state.bookmarks.find((bookmark) => bookmark.hash === action.payload.bookmarkHash);
      if (bookmark) {
        const data = bookmark.data as Bookmark1CObject;
        data.innerRows = action.payload.innerRows;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFiltersServerListThunk.fulfilled, (state, action) => {
        const filtersServerList = action.payload.data;
        if (filtersServerList.length > 0) {
          state.filtersServerList = filtersServerList;
          filtersServerList.forEach((serverFilter) => {
            if (serverFilter.type === 'string') {
              state.multiFilters.push({
                name: serverFilter.name,
                title: serverFilter.title,
                items: [],
                searchTitleValue: null,
              });
            } else if (serverFilter.type === 'date') {
              state.dataFilters.push({
                name: serverFilter.name,
                title: serverFilter.title,
                dataRange: [undefined, undefined],
              });
            }
          });
          const filtersGroupsUniq = filtersServerList
            .map((item) => item.group)
            .filter((value, index, self) => self.indexOf(value) === index && value);
          filtersGroupsUniq.forEach((filtersGroup) => {
            const filtersForGroup = filtersServerList.filter((filter) => filter.group === filtersGroup);
            if (filtersGroup && filtersForGroup.length > 0) {
              const title = filtersServerList.find((filter) => filter.group === filtersGroup)?.groupTitle;
              const groupFilter: FiltersGroupType = {
                groupName: filtersGroup,
                groupTitle: title || 'Дополнительно',
                groupFilters: filtersForGroup.map((filter) => ({
                  name: filter.name,
                  title: filter.title,
                  isChecked: false,
                })),
              };
              state.groupFilters.push(groupFilter);
            }
          });
        }
      })
      .addCase(getCatalogFilterDictionaryThunk.fulfilled, (state, action) => {
        const actionFilter = state.multiFilters.find((filter) => filter.name === action.payload.filterName);
        if (actionFilter) {
          actionFilter.items = action.payload.dictionary.data.map((item) => ({
            uuid: item.uuid,
            value: item.name,
            isChecked: false,
          }));
        }
      })

      .addCase(getCatalogItemsThunk.fulfilled, (state, action) => {
        state.catalogItems = action.payload;
        state.isLoadingCatalogItems = false;
      })
      .addCase(getCatalogItemsThunk.pending, (state) => {
        state.isLoadingCatalogItems = true;
      })
      .addCase(getCatalogItemsThunk.rejected, (state, action) => {
        state.isLoadingCatalogItems = false;
        state.isError = action.payload ? action.payload : 'Неизвестная ошибка - getCatalogItems';
      })

      .addCase(getHierarchyItemsThunk.fulfilled, (state, action) => {
        if (!action.payload.parentUuid) {
          const hierarchyItems = action.payload.hierarchyItems.map((item) => ({
            ...item,
            isChecked: false,
            isOpen: false,
          }));
          const newItem: {
            [key: string]: HierarchyItemType[];
          } = {};

          newItem[`${action.payload.hierarchySlug}`] = hierarchyItems;
          state.hierarchy = { ...state.hierarchy, ...newItem };
        } else {
          const hierarchyItems = state.hierarchy[`${action.payload.hierarchySlug}`];
          const parent = searchParentInHierarchyByChildUuid(hierarchyItems, action.payload.parentUuid);
          state.hierarchy[`${action.payload.hierarchySlug}`] = addChildrenToHierarchyItem({
            hierarchyItems: hierarchyItems,
            parentUuid: action.payload.parentUuid,
            children: action.payload.hierarchyItems.map((item) => ({
              ...item,
              isChecked: !!parent?.isChecked,
              isOpen: false,
            })),
          });
        }
        state.isLoadingHierarchy = false;
      })
      .addCase(getHierarchyItemsThunk.pending, (state) => {
        state.isLoadingHierarchy = true;
      })
      .addCase(getHierarchyItemsThunk.rejected, (state, action) => {
        state.isLoadingHierarchy = false;
        state.isError = action.payload ? action.payload : 'Неизвестная ошибка - catalogSlice';
      })

      .addCase(getBookmarkDataThunk.fulfilled, (state, action) => {
        if (action.payload.bookmark.hash) {
          state.bookmarks = state.bookmarks.map((bookmark) => {
            return bookmark.hash === action.payload.bookmark.hash
              ? { ...bookmark, label: action.payload.bookmarkData.name || '', data: action.payload.bookmarkData }
              : bookmark;
          });
        }
        state.isLoadingBookmarkData = false;
      })
      .addCase(getBookmarkDataThunk.pending, (state) => {
        state.isLoadingBookmarkData = true;
      })
      .addCase(getBookmarkDataThunk.rejected, (state, action) => {
        state.isLoadingBookmarkData = false;
        state.isError = action.payload ? action.payload : 'Неизвестная ошибка - catalogSlice';
      })

      .addCase(getBookmarkAttributesDataThunk.fulfilled, (state, action) => {
        if (action.payload.bookmark.hash) {
          state.bookmarks = state.bookmarks.map((bookmark) => {
            return bookmark.hash === action.payload.bookmark.hash
              ? {
                  ...bookmark,
                  data: {
                    ...bookmark.data,
                    attributesData: action.payload.attributesData,
                  } as BookmarkTableViewTableFunctionType | Bookmark1CObject,
                }
              : bookmark;
          });
        }
        state.isLoadingAttributesData = false;
      })
      .addCase(getBookmarkAttributesDataThunk.pending, (state) => {
        state.isLoadingAttributesData = true;
      })
      .addCase(getBookmarkAttributesDataThunk.rejected, (state, action) => {
        state.isLoadingAttributesData = false;
        state.isError = action.payload ? action.payload : 'Неизвестная ошибка - catalogSlice';
      })

      .addCase(getBookmarkPreviewDataThunk.fulfilled, (state, action) => {
        if (action.payload.bookmark.hash) {
          state.bookmarks = state.bookmarks.map((bookmark) => {
            return bookmark.hash === action.payload.bookmark.hash
              ? {
                  ...bookmark,
                  data: {
                    ...bookmark.data,
                    previewData: action.payload.previewData,
                  } as Bookmark1CObject,
                }
              : bookmark;
          });
        }
        state.isLoadingPreviewData = false;
      })
      .addCase(getBookmarkPreviewDataThunk.pending, (state) => {
        state.isLoadingPreviewData = true;
      })
      .addCase(getBookmarkPreviewDataThunk.rejected, (state, action) => {
        state.isLoadingPreviewData = false;
        state.isError = action.payload ? action.payload : 'Неизвестная ошибка - catalogSlice';
      })
      .addCase(getBookmarkScriptDataThunk.fulfilled, (state, action) => {
        if (action.payload.bookmark.hash) {
          state.bookmarks = state.bookmarks.map((bookmark) => {
            return bookmark.hash === action.payload.bookmark.hash
              ? {
                  ...bookmark,
                  data: {
                    ...bookmark.data,
                    scriptData: action.payload.scriptData,
                  } as BookmarkTableViewTableFunctionType | BookmarkProcedureTriggerFunctionType,
                }
              : bookmark;
          });
        }
        state.isLoadingScriptData = false;
      })
      .addCase(getBookmarkScriptDataThunk.pending, (state) => {
        state.isLoadingScriptData = true;
      })
      .addCase(getBookmarkScriptDataThunk.rejected, (state, action) => {
        state.isLoadingScriptData = false;
        state.isError = action.payload ? action.payload : 'Неизвестная ошибка - catalogSlice';
      })

      .addCase(updateDescriptionThunk.fulfilled, (state, action) => {
        if (action.payload.bookmark.hash) {
          state.bookmarks = state.bookmarks.map((bookmark) => {
            return bookmark.hash === action.payload.bookmark.hash
              ? {
                  ...bookmark,
                  data: { ...action.payload.bookmarkData },
                }
              : bookmark;
          });

          const newStateCatalogItemsData = state.catalogItems?.data.map((catalogItem) => {
            const description = action.payload.bookmarkData.description as BookmarkDescriptionType | undefined;
            return catalogItem._hash_key === action.payload.bookmark.hash
              ? {
                  ...catalogItem,
                  description_name: description?.name ? description.name : null,
                  description_value: description?.value ? description.value : null,
                }
              : catalogItem;
          });

          if (state.catalogItems && !!newStateCatalogItemsData?.length) {
            state.catalogItems = { ...state.catalogItems, data: newStateCatalogItemsData };
          }
        }
        state.isUpdatingDescription = false;
      })
      .addCase(updateDescriptionThunk.pending, (state) => {
        state.isUpdatingDescription = true;
      })
      .addCase(updateDescriptionThunk.rejected, (state, action) => {
        state.isUpdatingDescription = false;
        state.isError = action.payload ? action.payload : 'Неизвестная ошибка - catalogSlice';
      })

      .addCase(getObjectsThunk.fulfilled, (state, action) => {
        const bookmark = state.bookmarks.find((bookmark) => bookmark.hash === action.payload.bookmark.hash);
        if (bookmark) {
          if (!bookmark.objects) {
            bookmark.objects = {};
            const newObjectsData: { [p: string]: GetCatalogItemsServerResponseType } = {};
            newObjectsData[`${action.payload.objectType.slug}`] = action.payload.objectsData;
            bookmark.objects.objectsData = newObjectsData;
          } else {
            if (bookmark.objects.objectsData) {
              const newObjectsData = { ...bookmark.objects.objectsData };
              newObjectsData[`${action.payload.objectType.slug}`] = action.payload.objectsData;
              bookmark.objects.objectsData = newObjectsData;
            } else {
              const newObjectsData: { [p: string]: GetCatalogItemsServerResponseType } = {};
              newObjectsData[`${action.payload.objectType.slug}`] = action.payload.objectsData;
              bookmark.objects.objectsData = newObjectsData;
            }
          }
          bookmark.objects.isLoadingObjects = false;
        }
      })
      .addCase(getObjectsThunk.pending, (state, action) => {
        const bookmark = state.bookmarks.find((bookmark) => bookmark.hash === action.meta.arg.bookmark.hash);
        if (bookmark) {
          if (!bookmark.objects) {
            bookmark.objects = {};
          }
          bookmark.objects.isLoadingObjects = true;
        }
      })
      .addCase(getObjectsThunk.rejected, (state, action) => {
        const bookmark = state.bookmarks.find((bookmark) => bookmark.hash === action.meta.arg.bookmark.hash);
        if (bookmark) {
          if (!bookmark.objects) {
            bookmark.objects = {};
          }
          bookmark.objects.isLoadingObjects = false;
        }
        state.isError = action.payload ? action.payload : 'Неизвестная ошибка - getCatalogItems';
      })

      .addCase(getObjectsTypesThunk.fulfilled, (state, action) => {
        const bookmark = state.bookmarks.find((bookmark) => bookmark.hash === action.payload.bookmark.hash);
        if (bookmark) {
          if (!bookmark.objects) {
            bookmark.objects = {};
          }
          bookmark.objects.objectTypes = action.payload.objectsTypes.data;
          bookmark.objects.isLoadingObjects = false;
        }
      })
      .addCase(getObjectsTypesThunk.pending, (state, action) => {
        const bookmark = state.bookmarks.find((bookmark) => bookmark.hash === action.meta.arg.data?._uuid);
        if (bookmark) {
          if (!bookmark.objects) {
            bookmark.objects = {};
          }
          bookmark.objects.isLoadingObjects = true;
        }
      })
      .addCase(getObjectsTypesThunk.rejected, (state, action) => {
        const bookmark = state.bookmarks.find((bookmark) => bookmark.hash === action.meta.arg.data?._uuid);
        if (bookmark) {
          if (!bookmark.objects) {
            bookmark.objects = {};
          }
          bookmark.objects.isLoadingObjects = false;
        }
        state.isError = action.payload ? action.payload : 'Неизвестная ошибка - getCatalogItems';
      })

      .addMatcher(
        isAnyOf(getFiltersServerListThunk.rejected, getCatalogFilterDictionaryThunk.rejected),
        (state, action) => {
          state.isLoading = false;
          state.isError = action.payload ? action.payload : 'Неизвестная ошибка - catalogSlice';
        }
      );
  },
});

export const {
  clearCatalogError,
  setCatalogError,
  setActiveSection,
  setMultiFiltersStore,
  setDataFiltersStore,
  setGroupFiltersStore,
  setIsFiltersDictionaryAsked,
  setActiveBookmarkKey,
  toggleIsOpenHierarchy,
  closeIsOpenHierarchy,
  setActiveSortingId,
  setIsSelectHierarchyItemStore,
  setIsOpenHierarchyItemStore,
  closeAllHierarchyItemsStore,
  setIsSelectForHierarchyItemAndAllChildrenStore,
  checkAndDeCheckParents,
  setNoSelectAllHierarchyItemsStore,
  setActiveViewSelectorName,
  setTableColumns,
  createNewBookmark,
  deleteBookmark,
  setActiveObjectTypeTab,
  setObjectsSearchValue,
  setBookmarkActiveTab,
  setBookmarkTableColumns,
  setBookmarkOpenedRows,
  setBookmarkInnerRows,
} = catalogSlice.actions;

export const selectorCatalogSliceError = (state: RootState) => state.catalog.isError;
export const selectorCatalogActiveBookmarkKey = (state: RootState) => state.catalog.activeBookmarkKey;
export const selectorCatalogBookmarks = (state: RootState) => state.catalog.bookmarks;
export const selectorCatalogActiveSortingId = (state: RootState) => state.catalog.activeSortingId;

export const selectorCatalogActiveSection = (state: RootState) => state.catalog.activeSection;
export const selectorCatalogActiveViewSelectorName = (state: RootState) => state.catalog.activeViewSelectorName;
export const selectorCatalogItems = (state: RootState) => state.catalog.catalogItems;
export const selectorCatalogsIsLoadingItems = (state: RootState) => state.catalog.isLoadingCatalogItems;

export const selectorCatalogTableColumns = (state: RootState) => state.catalog.tableColumns;

export const selectorCatalogIsHierarchyOpen = (state: RootState) => state.catalog.isHierarchyOpen;
export const selectorCatalogsIsLoadingHierarchy = (state: RootState) => state.catalog.isLoadingHierarchy;

export const selectorCatalogHierarchy = (state: RootState) => state.catalog.hierarchy;

export const selectorCatalogsIsLoadingBookmarkData = (state: RootState) => state.catalog.isLoadingBookmarkData;
export const selectorCatalogsIsLoadingAttributesData = (state: RootState) => state.catalog.isLoadingAttributesData;
export const selectorCatalogsIsLoadingPreviewData = (state: RootState) => state.catalog.isLoadingPreviewData;
export const selectorCatalogsIsLoadingScriptData = (state: RootState) => state.catalog.isLoadingScriptData;
export const selectorCatalogsIsUpdatingDescription = (state: RootState) => state.catalog.isUpdatingDescription;

export const selectorCatalogMultiFilters = (state: RootState) => state.catalog.multiFilters;
export const selectorCatalogFiltersServerList = (state: RootState) => state.catalog.filtersServerList;

export const selectorCatalogDataFiltersStore = (state: RootState) => state.catalog.dataFilters;

export const selectorCatalogGroupFilters = (state: RootState) => state.catalog.groupFilters;
export const selectorCatalogIsFiltersDictionaryAsked = (state: RootState) => state.catalog.isFiltersDictionaryAsked;

const selectorMetaAssetsByType = (state: RootState) => state.meta.assetsByType;
export const selectorCatalogSections = createSelector([selectorMetaAssetsByType], (assetsByType) => {
  return assetsByType.map((section) => {
    const catalogSection: CatalogSectionType = {
      id: section.slug,
      shortName: section.short_name,
      label: section.name,
      disabled: !section.assets_count,
      slug: section.slug,
    };
    return catalogSection;
  });
});
export const selectorCatalogDataFilters = createSelector([selectorCatalogDataFiltersStore], (dataFiltersStore) => {
  return dataFiltersStore.map(
    (dataFilter) =>
      ({
        ...dataFilter,
        dataRange: [
          dataFilter.dataRange[0] ? new Date(dataFilter.dataRange[0]) : undefined,
          dataFilter.dataRange[1] ? new Date(dataFilter.dataRange[1]) : undefined,
        ],
      } as DataFilterType)
  );
});

export const selectorCatalogHierarchyItems = createSelector(
  [selectorCatalogHierarchy, selectorCatalogActiveSection],
  (catalogHierarchy, catalogActiveSection) => {
    if (catalogHierarchy && catalogActiveSection && catalogActiveSection.slug) {
      const keys = Object.keys(catalogHierarchy);
      const hasActiveHierarchyKey = keys.find((key) => key === `${catalogActiveSection.slug}`);
      return hasActiveHierarchyKey ? catalogHierarchy[`${catalogActiveSection.slug}`] : [];
    } else {
      return [];
    }
  }
);

export const selectorCatalogActiveSectionTableColumns = createSelector(
  [selectorCatalogTableColumns, selectorCatalogActiveSection],
  (catalogTableColumns, catalogActiveSection) => {
    if (catalogTableColumns && catalogActiveSection && catalogActiveSection.slug) {
      const keys = Object.keys(catalogTableColumns);
      const hasActiveTableKey = keys.find((key) => key === `${catalogActiveSection.slug}`);
      return hasActiveTableKey ? catalogTableColumns[`${catalogActiveSection.slug}`] : [];
    } else {
      return [];
    }
  }
);

export default catalogSlice.reducer;
